/**************************************************************************************************
 * hoobs-gui                                                                                      *
 * Copyright (C) 2020 HOOBS                                                                       *
 *                                                                                                *
 * This program is free software: you can redistribute it and/or modify                           *
 * it under the terms of the GNU General Public License as published by                           *
 * the Free Software Foundation, either version 3 of the License, or                              *
 * (at your option) any later version.                                                            *
 *                                                                                                *
 * This program is distributed in the hope that it will be useful,                                *
 * but WITHOUT ANY WARRANTY; without even the implied warranty of                                 *
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the                                  *
 * GNU General Public License for more details.                                                   *
 *                                                                                                *
 * You should have received a copy of the GNU General Public License                              *
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.                          *
 **************************************************************************************************/

import {
    mdiPipeLeak,
    mdiChevronLeft,
    mdiChevronRight,
    mdiViewDashboard,
    mdiLightbulbOn,
    mdiTextBox,
    mdiAccountMultiple,
    mdiLayers,
    mdiPuzzle,
    mdiCog,
    mdiAbTesting,
    mdiAccessPoint,
    mdiAccessPointNetwork,
    mdiAccount,
    mdiAccountChild,
    mdiAccountCowboyHat,
    mdiAccountGroup,
    mdiAccountHardHat,
    mdiAccountTie,
    mdiAccountVoice,
    mdiAdjust,
    mdiAirConditioner,
    mdiAirFilter,
    mdiAirHorn,
    mdiAirHumidifier,
    mdiAirPurifier,
    mdiAirbag,
    mdiAirballoon,
    mdiAirplane,
    mdiAlarm,
    mdiAlarmBell,
    mdiAlarmLight,
    mdiAlbum,
    mdiAlert,
    mdiAlertOctagon,
    mdiAlien,
    mdiAllInclusive,
    mdiAllergy,
    mdiAlphabetAurebesh,
    mdiAlphabetPiqad,
    mdiAltimeter,
    mdiAmazonAlexa,
    mdiAmbulance,
    mdiAmmunition,
    mdiAmplifier,
    mdiAnchor,
    mdiAndroid,
    mdiAndroidAuto,
    mdiAndroidStudio,
    mdiAnimation,
    mdiAntenna,
    mdiAnvil,
    mdiApple,
    mdiAppleAirplay,
    mdiApproximatelyEqual,
    mdiApps,
    mdiArch,
    mdiArchive,
    mdiArmFlex,
    mdiArtstation,
    mdiAspectRatio,
    mdiAtlassian,
    mdiAtomVariant,
    mdiAttachment,
    mdiAudioVideo,
    mdiAutoFix,
    mdiAutorenew,
    mdiAvTimer,
    mdiAxe,
    mdiBaby,
    mdiBabyBottle,
    mdiBabyBuggy,
    mdiBabyCarriage,
    mdiBabyFace,
    mdiBackupRestore,
    mdiBacteria,
    mdiBadgeAccount,
    mdiBadminton,
    mdiBagCarryOn,
    mdiBagChecked,
    mdiBagPersonal,
    mdiBaguette,
    mdiBalloon,
    mdiBandage,
    mdiBank,
    mdiBarcodeScan,
    mdiBarley,
    mdiBarn,
    mdiBarrel,
    mdiBaseball,
    mdiBaseballBat,
    mdiBasket,
    mdiBasketball,
    mdiBasketballHoop,
    mdiBat,
    mdiBatteryOutline,
    mdiBattlenet,
    mdiBeach,
    mdiBeaker,
    mdiBed,
    mdiBedQueen,
    mdiBee,
    mdiBeer,
    mdiBell,
    mdiBeta,
    mdiBetamax,
    mdiBiathlon,
    mdiBicycle,
    mdiBike,
    mdiBillboard,
    mdiBilliardsRack,
    mdiBinoculars,
    mdiBiohazard,
    mdiBlender,
    mdiBlinds,
    mdiBloodBag,
    mdiBlur,
    mdiBolnisiCross,
    mdiBolt,
    mdiBomb,
    mdiBone,
    mdiBook,
    mdiBookOpenBlankVariant,
    mdiBookOpenPageVariant,
    mdiBookmark,
    mdiBookshelf,
    mdiBoomGate,
    mdiBoombox,
    mdiBoomerang,
    mdiBottleSodaClassic,
    mdiBottleTonic,
    mdiBottleWine,
    mdiBowTie,
    mdiBowlMix,
    mdiBowling,
    mdiBoxCutter,
    mdiBoxingGlove,
    mdiBraille,
    mdiBrain,
    mdiBreadSlice,
    mdiBridge,
    mdiBriefcase,
    mdiBriefcaseVariant,
    mdiBrightness7,
    mdiBroom,
    mdiBrush,
    mdiBucket,
    mdiBuffer,
    mdiBuffet,
    mdiBug,
    mdiBugle,
    mdiBulldozer,
    mdiBullet,
    mdiBulletinBoard,
    mdiBullhorn,
    mdiBullseyeArrow,
    mdiBunkBed,
    mdiBus,
    mdiCableData,
    mdiCactus,
    mdiCakeVariant,
    mdiCalculator,
    mdiCamcorder,
    mdiCamera,
    mdiCameraControl,
    mdiCameraGopro,
    mdiCameraIris,
    mdiCampfire,
    mdiCandle,
    mdiCandycane,
    mdiCannabis,
    mdiCar,
    mdiCarBack,
    mdiCarBattery,
    mdiCarChildSeat,
    mdiCarClutch,
    mdiCarConnected,
    mdiCarConvertible,
    mdiCarDefrostRear,
    mdiCarDoor,
    mdiCarEstate,
    mdiCarHatchback,
    mdiCarLiftedPickup,
    mdiCarLimousine,
    mdiCarMultiple,
    mdiCarParkingLights,
    mdiCarPickup,
    mdiCarSeat,
    mdiCarShiftPattern,
    mdiCarTractionControl,
    mdiCaravan,
    mdiCards,
    mdiCardsClub,
    mdiCardsDiamond,
    mdiCardsHeart,
    mdiCardsSpade,
    mdiCarrot,
    mdiCart,
    mdiCash,
    mdiCashRegister,
    mdiCassette,
    mdiCastAudio,
    mdiCastConnected,
    mdiCastle,
    mdiCat,
    mdiCctv,
    mdiCeilingLight,
    mdiCellphoneBasic,
    mdiCellphoneIphone,
    mdiCellphoneLink,
    mdiCelticCross,
    mdiCertificate,
    mdiChairRolling,
    mdiChairSchool,
    mdiCharity,
    mdiCheckBold,
    mdiCheckerboard,
    mdiCheese,
    mdiChefHat,
    mdiChemicalWeapon,
    mdiChessBishop,
    mdiChessKing,
    mdiChessKnight,
    mdiChessPawn,
    mdiChessQueen,
    mdiChessRook,
    mdiChiliMild,
    mdiChip,
    mdiChristianity,
    mdiChurch,
    mdiCigar,
    mdiCircle,
    mdiCircleDouble,
    mdiCircularSaw,
    mdiCity,
    mdiCityVariant,
    mdiClipboard,
    mdiClippy,
    mdiClock,
    mdiClosedCaption,
    mdiCloud,
    mdiClover,
    mdiCoachLamp,
    mdiCoatRack,
    mdiCodeJson,
    mdiCoffee,
    mdiCoffeeMaker,
    mdiCoffin,
    mdiCogs,
    mdiCollage,
    mdiComma,
    mdiComment,
    mdiCompare,
    mdiCompass,
    mdiConcourseCi,
    mdiConsoleLine,
    mdiContactlessPayment,
    mdiContentCut,
    mdiContentPaste,
    mdiContentSave,
    mdiContrast,
    mdiControllerClassic,
    mdiCookie,
    mdiCoolantTemperature,
    mdiCorn,
    mdiCounter,
    mdiCow,
    mdiCrane,
    mdiCreation,
    mdiCreditCard,
    mdiCricket,
    mdiCrosshairs,
    mdiCrosshairsGps,
    mdiCrown,
    mdiCryengine,
    mdiCrystalBall,
    mdiCube,
    mdiCubeUnfolded,
    mdiCup,
    mdiCupboard,
    mdiCupcake,
    mdiCurling,
    mdiCurrentAc,
    mdiCurrentDc,
    mdiCursorDefaultClick,
    mdiCursorDefaultGesture,
    mdiCursorMove,
    mdiCursorPointer,
    mdiDataMatrix,
    mdiDatabase,
    mdiDeathStar,
    mdiDeathStarVariant,
    mdiDeathlyHallows,
    mdiDecagram,
    mdiDelete,
    mdiDelta,
    mdiDesk,
    mdiDeskLamp,
    mdiDeskphone,
    mdiDesktopClassic,
    mdiDesktopMac,
    mdiDesktopTower,
    mdiDesktopTowerMonitor,
    mdiDeveloperBoard,
    mdiDevices,
    mdiDiabetes,
    mdiDialpad,
    mdiDiamond,
    mdiDiamondStone,
    mdiDice1,
    mdiDice2,
    mdiDice3,
    mdiDice4,
    mdiDice5,
    mdiDice6,
    mdiDiceMultiple,
    mdiDipSwitch,
    mdiDirections,
    mdiDirectionsFork,
    mdiDisc,
    mdiDishwasher,
    mdiDivingFlippers,
    mdiDivingHelmet,
    mdiDivingScuba,
    mdiDivingScubaTank,
    mdiDivingScubaTankMultiple,
    mdiDivingSnorkel,
    mdiDlna,
    mdiDna,
    mdiDns,
    mdiDoctor,
    mdiDog,
    mdiDogSide,
    mdiDolby,
    mdiDolly,
    mdiDomain,
    mdiDomeLight,
    mdiDominoMask,
    mdiDonkey,
    mdiDoor,
    mdiDoorClosed,
    mdiDoorOpen,
    mdiDoorbellVideo,
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiDouban,
    mdiDownload,
    mdiDraw,
    mdiDrawing,
    mdiDresser,
    mdiDrone,
    mdiDuck,
    mdiDumbbell,
    mdiDumpTruck,
    mdiEarHearing,
    mdiEarth,
    mdiEgg,
    mdiEggEaster,
    mdiEightTrack,
    mdiEject,
    mdiElectricSwitch,
    mdiElectricSwitchClosed,
    mdiElephant,
    mdiElevatorPassenger,
    mdiEllipse,
    mdiEmail,
    mdiEmoticon,
    mdiEngine,
    mdiEqual,
    mdiEqualizer,
    mdiEraser,
    mdiEscalator,
    mdiEthereum,
    mdiEthernet,
    mdiEthernetCable,
    mdiEvPlugCcs1,
    mdiEvPlugCcs2,
    mdiEvPlugChademo,
    mdiEvPlugTesla,
    mdiEvPlugType1,
    mdiEvPlugType2,
    mdiEvStation,
    mdiExcavator,
    mdiExitRun,
    mdiExpansionCardVariant,
    mdiEye,
    mdiEyedropper,
    mdiFace,
    mdiFaceAgent,
    mdiFaceProfile,
    mdiFaceProfileWoman,
    mdiFaceWoman,
    mdiFactory,
    mdiFan,
    mdiFax,
    mdiFeather,
    mdiFeatureSearch,
    mdiFencing,
    mdiFerrisWheel,
    mdiFerry,
    mdiFile,
    mdiFilm,
    mdiFilmstrip,
    mdiFilter,
    mdiFinance,
    mdiFingerprint,
    mdiFire,
    mdiFireExtinguisher,
    mdiFireHydrant,
    mdiFireTruck,
    mdiFireplace,
    mdiFirework,
    mdiFish,
    mdiFishbowl,
    mdiFlag,
    mdiFlare,
    mdiFlash,
    mdiFlashlight,
    mdiFlask,
    mdiFlaskEmpty,
    mdiFleurDeLis,
    mdiFloorLamp,
    mdiFloorLampDual,
    mdiFloorLampVariant,
    mdiFloorPlan,
    mdiFloppy,
    mdiFloppyVariant,
    mdiFlower,
    mdiFlowerOutline,
    mdiFlowerPoppy,
    mdiFlowerTulip,
    mdiFlowerTulipOutline,
    mdiFolder,
    mdiFood,
    mdiFoodApple,
    mdiFoodCroissant,
    mdiFoodDrumstick,
    mdiFoodForkDrink,
    mdiFoodSteak,
    mdiFoodVariant,
    mdiFootPrint,
    mdiFootball,
    mdiFootballHelmet,
    mdiForklift,
    mdiForum,
    mdiFountain,
    mdiFountainPen,
    mdiFountainPenTip,
    mdiFridge,
    mdiFruitCherries,
    mdiFruitCitrus,
    mdiFruitGrapes,
    mdiFruitPineapple,
    mdiFruitWatermelon,
    mdiFuel,
    mdiFuse,
    mdiFuseBlade,
    mdiGamepad,
    mdiGamepadCircle,
    mdiGamepadSquare,
    mdiGamepadVariant,
    mdiGantryCrane,
    mdiGarage,
    mdiGarageOpen,
    mdiGarageOpenVariant,
    mdiGarageVariant,
    mdiGasCylinder,
    mdiGasStation,
    mdiGate,
    mdiGateAnd,
    mdiGateNand,
    mdiGateNor,
    mdiGateNot,
    mdiGateOpen,
    mdiGateOr,
    mdiGateXnor,
    mdiGateXor,
    mdiGauge,
    mdiGavel,
    mdiGesture,
    mdiGhost,
    mdiGift,
    mdiGlassCocktail,
    mdiGlassFlute,
    mdiGlassMug,
    mdiGlassMugVariant,
    mdiGlassPintOutline,
    mdiGlassStange,
    mdiGlassTulip,
    mdiGlassWine,
    mdiGlasses,
    mdiGlobeLight,
    mdiGlobeModel,
    mdiGoKart,
    mdiGoKartTrack,
    mdiGold,
    mdiGolf,
    mdiGolfCart,
    mdiGolfTee,
    mdiGondola,
    mdiGoogleAssistant,
    mdiGoogleCardboard,
    mdiGoogleDownasaur,
    mdiGoogleHome,
    mdiGradient,
    mdiGrain,
    mdiGrass,
    mdiGraveStone,
    mdiGreasePencil,
    mdiGrid,
    mdiGridLarge,
    mdiGrill,
    mdiGuitarAcoustic,
    mdiGuitarElectric,
    mdiGuitarPick,
    mdiHail,
    mdiHairDryer,
    mdiHalloween,
    mdiHamburger,
    mdiHammer,
    mdiHammerScrewdriver,
    mdiHammerWrench,
    mdiHand,
    mdiHandHeart,
    mdiHandLeft,
    mdiHandOkay,
    mdiHandPeace,
    mdiHandRight,
    mdiHandSaw,
    mdiHandWater,
    mdiHandball,
    mdiHandcuffs,
    mdiHandshake,
    mdiHanger,
    mdiHardHat,
    mdiHarddisk,
    mdiHatFedora,
    mdiHazardLights,
    mdiHead,
    mdiHeadphones,
    mdiHeadset,
    mdiHeadsetDock,
    mdiHeart,
    mdiHelicopter,
    mdiHelp,
    mdiHexadecimal,
    mdiHexagon,
    mdiHexagonMultiple,
    mdiHexagram,
    mdiHighway,
    mdiHiking,
    mdiHockeyPuck,
    mdiHockeySticks,
    mdiHololens,
    mdiHome,
    mdiHomeGroup,
    mdiHomeModern,
    mdiHomeRoof,
    mdiHook,
    mdiHops,
    mdiHorseshoe,
    mdiHospitalBuilding,
    mdiHotTub,
    mdiHours24,
    mdiHuman,
    mdiHumanBabyChangingTable,
    mdiHumanChild,
    mdiHumanFemale,
    mdiHumanFemaleBoy,
    mdiHumanFemaleGirl,
    mdiHumanGreeting,
    mdiHumanHandsdown,
    mdiHumanHandsup,
    mdiHumanMale,
    mdiHumanMaleBoy,
    mdiHumanMaleChild,
    mdiHumanMaleFemale,
    mdiHumanMaleGirl,
    mdiHumanPregnant,
    mdiHumanWheelchair,
    mdiHvac,
    mdiHydroPower,
    mdiIceCream,
    mdiIcePop,
    mdiIdCard,
    mdiImage,
    mdiIncognito,
    mdiInfinity,
    mdiInformation,
    mdiInstrumentTriangle,
    mdiInvertColors,
    mdiIpod,
    mdiIsland,
    mdiIvBag,
    mdiJeepney,
    mdiJellyfish,
    mdiJumpRope,
    mdiKabaddi,
    mdiKarate,
    mdiKeg,
    mdiKettle,
    mdiKettlebell,
    mdiKey,
    mdiKeyboard,
    mdiKnife,
    mdiKnifeMilitary,
    mdiLabel,
    mdiLadybug,
    mdiLamp,
    mdiLan,
    mdiLaptop,
    mdiLaserPointer,
    mdiLasso,
    mdiLatitude,
    mdiLavaLamp,
    mdiLeadPencil,
    mdiLeaf,
    mdiLeafMaple,
    mdiLeak,
    mdiLedOff,
    mdiLedOn,
    mdiLedOutline,
    mdiLedStrip,
    mdiLedStripVariant,
    mdiLeek,
    mdiLibrary,
    mdiLibraryShelves,
    mdiLicense,
    mdiLifebuoy,
    mdiLightSwitch,
    mdiLightbulb,
    mdiLightbulbCfl,
    mdiLightbulbCflSpiral,
    mdiLightbulbGroup,
    mdiLightbulbGroupOutline,
    mdiLightbulbMultiple,
    mdiLightbulbMultipleOutline,
    mdiLightbulbOnOutline,
    mdiLightbulbOutline,
    mdiLighthouse,
    mdiLighthouseOn,
    mdiLightningBolt,
    mdiLingerie,
    mdiLink,
    mdiLinkVariant,
    mdiLipstick,
    mdiLock,
    mdiLockOpen,
    mdiLockOpenVariant,
    mdiLockPattern,
    mdiLockSmart,
    mdiLocker,
    mdiLockerMultiple,
    mdiLongitude,
    mdiLooks,
    mdiLungs,
    mdiMagnet,
    mdiMagnetOn,
    mdiMagnify,
    mdiMail,
    mdiMailbox,
    mdiMailboxOpen,
    mdiMap,
    mdiMapMarker,
    mdiMarker,
    mdiMathCompass,
    mdiMedal,
    mdiMedicalBag,
    mdiMeditation,
    mdiMemory,
    mdiMenu,
    mdiMenuOpen,
    mdiMerge,
    mdiMessage,
    mdiMeteor,
    mdiMicroSd,
    mdiMicrophone,
    mdiMicrophoneVariant,
    mdiMicroscope,
    mdiMicrosoftXbox,
    mdiMicrowave,
    mdiMidiPort,
    mdiMinecraft,
    mdiMiniSd,
    mdiMinidisc,
    mdiMirror,
    mdiMixedMartialArts,
    mdiMolecule,
    mdiMoleculeCo,
    mdiMoleculeCo2,
    mdiMonitor,
    mdiMoped,
    mdiMotherHeart,
    mdiMotherNurse,
    mdiMotionSensor,
    mdiMotorbike,
    mdiMouse,
    mdiMouseVariant,
    mdiMovie,
    mdiMovieRoll,
    mdiMuffin,
    mdiMushroom,
    mdiMusic,
    mdiNail,
    mdiNas,
    mdiNature,
    mdiNaturePeople,
    mdiNecklace,
    mdiNeedle,
    mdiNetwork,
    mdiNewspaper,
    mdiNfc,
    mdiNinja,
    mdiNintendoGameBoy,
    mdiNintendoSwitch,
    mdiNoodles,
    mdiNotebook,
    mdiNuke,
    mdiNut,
    mdiNutrition,
    mdiOar,
    mdiOctagon,
    mdiOctagram,
    mdiOffer,
    mdiOfficeBuilding,
    mdiOil,
    mdiOilLamp,
    mdiOilLevel,
    mdiOneUp,
    mdiOpacity,
    mdiOrbit,
    mdiOrigin,
    mdiOrnament,
    mdiOutdoorLamp,
    mdiOwl,
    mdiPacMan,
    mdiPackage,
    mdiPail,
    mdiPalette,
    mdiPaletteSwatch,
    mdiPalmTree,
    mdiPanda,
    mdiPanorama,
    mdiPaperRoll,
    mdiPaperclip,
    mdiParachute,
    mdiPartyPopper,
    mdiPassport,
    mdiPassportBiometric,
    mdiPasta,
    mdiPatioHeater,
    mdiPaw,
    mdiPeace,
    mdiPeanut,
    mdiPen,
    mdiPencil,
    mdiPenguin,
    mdiPentagon,
    mdiPeriodicTable,
    mdiPharmacy,
    mdiPhone,
    mdiPhoneClassic,
    mdiPi,
    mdiPiHole,
    mdiPiano,
    mdiPickaxe,
    mdiPier,
    mdiPierCrane,
    mdiPigVariant,
    mdiPiggyBank,
    mdiPill,
    mdiPillar,
    mdiPin,
    mdiPineTree,
    mdiPineTreeFire,
    mdiPinwheel,
    mdiPipe,
    mdiPipeDisconnected,
    mdiPipeWrench,
    mdiPirate,
    mdiPistol,
    mdiPiston,
    mdiPizza,
    mdiPlex,
    mdiPlus,
    mdiPodcast,
    mdiPodium,
    mdiPointOfSale,
    mdiPokerChip,
    mdiPoliceBadge,
    mdiPolo,
    mdiPool,
    mdiPopcorn,
    mdiPostageStamp,
    mdiPotMix,
    mdiPower,
    mdiPowerPlug,
    mdiPowerSocket,
    mdiPowerSocketUs,
    mdiPrescription,
    mdiPresentation,
    mdiPrinter,
    mdiPrinter3d,
    mdiProjector,
    mdiProjectorScreen,
    mdiPropaneTank,
    mdiPulse,
    mdiPump,
    mdiPumpkin,
    mdiPurse,
    mdiQi,
    mdiQrcode,
    mdiQuadcopter,
    mdiRabbit,
    mdiRacingHelmet,
    mdiRacquetball,
    mdiRadar,
    mdiRadiator,
    mdiRadio,
    mdiRadioHandheld,
    mdiRadioTower,
    mdiRadioactive,
    mdiRailroadLight,
    mdiRake,
    mdiRaspberryPi,
    mdiReceipt,
    mdiRectangle,
    mdiRectangleOutline,
    mdiRecycle,
    mdiReminder,
    mdiRemote,
    mdiRemoteTv,
    mdiRice,
    mdiRing,
    mdiRivet,
    mdiRoadVariant,
    mdiRobber,
    mdiRobot,
    mdiRobotIndustrial,
    mdiRobotMower,
    mdiRobotVacuum,
    mdiRobotVacuumVariant,
    mdiRocket,
    mdiRocketLaunch,
    mdiRodent,
    mdiRollerSkate,
    mdiRollerblade,
    mdiRoomService,
    mdiRouter,
    mdiRouterNetwork,
    mdiRouterWireless,
    mdiRoutes,
    mdiRowing,
    mdiRss,
    mdiRug,
    mdiRugby,
    mdiRuler,
    mdiRulerSquare,
    mdiRun,
    mdiRunFast,
    mdiRvTruck,
    mdiSack,
    mdiSafe,
    mdiSafeSquare,
    mdiSafeSquareOutline,
    mdiSafetyGoggles,
    mdiSailBoat,
    mdiSatelliteUplink,
    mdiSatelliteVariant,
    mdiSausage,
    mdiSawBlade,
    mdiSaxophone,
    mdiScale,
    mdiScaleBalance,
    mdiScaleBathroom,
    mdiScanner,
    mdiSchool,
    mdiScooter,
    mdiScoreboard,
    mdiScrewFlatTop,
    mdiScrewLag,
    mdiScrewMachineFlatTop,
    mdiScrewMachineRoundTop,
    mdiScrewRoundTop,
    mdiScrewdriver,
    mdiSd,
    mdiSeal,
    mdiSeat,
    mdiSeatFlat,
    mdiSeatFlatAngled,
    mdiSeatIndividualSuite,
    mdiSeatLegroomExtra,
    mdiSeatLegroomNormal,
    mdiSeatLegroomReduced,
    mdiSeatPassenger,
    mdiSeatReclineExtra,
    mdiSeatReclineNormal,
    mdiSeatbelt,
    mdiSecurity,
    mdiSeed,
    mdiSend,
    mdiSerialPort,
    mdiServer,
    mdiServerNetwork,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiThermometer,
    mdiClose,
    mdiBattery,
    mdiBattery10,
    mdiBattery20,
    mdiBattery30,
    mdiBattery40,
    mdiBattery50,
    mdiBattery60,
    mdiBattery70,
    mdiBattery80,
    mdiBattery90,
    mdiWaterOutline,
    mdiSmog,
    mdiDockWindow,
    mdiSnowflake,
    mdiUpdate,
    mdiBellOutline,
    mdiHelpCircle,
    mdiCodeTags,
    mdiDragHorizontal,
    mdiStar,
    mdiMessageDraw,
    mdiChevronDown,
    mdiChevronUp,
    mdiPlusCircle,
    mdiAccountCircle,
    mdiBugCheck,
    mdiRefresh,
    mdiWrench,
    mdiEyeOff,
    mdiWidgets,
    mdiSprinklerVariant,
    mdiTelevisionClassic,
} from "@mdi/js";

const tags: { [key: string]: string } = {
    "pipe-leak": mdiPipeLeak,
    "chevron-left": mdiChevronLeft,
    "chevron-right": mdiChevronRight,
    "view-dashboard": mdiViewDashboard,
    "lightbulb-on": mdiLightbulbOn,
    "text-box": mdiTextBox,
    "account-multiple": mdiAccountMultiple,
    layers: mdiLayers,
    puzzle: mdiPuzzle,
    cog: mdiCog,
    "ab-testing": mdiAbTesting,
    "access-point": mdiAccessPoint,
    "access-point-network": mdiAccessPointNetwork,
    account: mdiAccount,
    "account-child": mdiAccountChild,
    "account-cowboy-hat": mdiAccountCowboyHat,
    "account-group": mdiAccountGroup,
    "account-hard-hat": mdiAccountHardHat,
    "account-tie": mdiAccountTie,
    "account-voice": mdiAccountVoice,
    adjust: mdiAdjust,
    "air-conditioner": mdiAirConditioner,
    "air-filter": mdiAirFilter,
    "air-horn": mdiAirHorn,
    "air-humidifier": mdiAirHumidifier,
    "air-purifier": mdiAirPurifier,
    airbag: mdiAirbag,
    airballoon: mdiAirballoon,
    airplane: mdiAirplane,
    alarm: mdiAlarm,
    "alarm-bell": mdiAlarmBell,
    "alarm-light": mdiAlarmLight,
    album: mdiAlbum,
    alert: mdiAlert,
    "alert-octagon": mdiAlertOctagon,
    alien: mdiAlien,
    "all-inclusive": mdiAllInclusive,
    allergy: mdiAllergy,
    "alphabet-aurebesh": mdiAlphabetAurebesh,
    "alphabet-piqad": mdiAlphabetPiqad,
    altimeter: mdiAltimeter,
    "amazon-alexa": mdiAmazonAlexa,
    ambulance: mdiAmbulance,
    ammunition: mdiAmmunition,
    amplifier: mdiAmplifier,
    anchor: mdiAnchor,
    android: mdiAndroid,
    "android-auto": mdiAndroidAuto,
    "android-studio": mdiAndroidStudio,
    animation: mdiAnimation,
    antenna: mdiAntenna,
    anvil: mdiAnvil,
    apple: mdiApple,
    "apple-airplay": mdiAppleAirplay,
    "approximately-equal": mdiApproximatelyEqual,
    apps: mdiApps,
    arch: mdiArch,
    archive: mdiArchive,
    "arm-flex": mdiArmFlex,
    artstation: mdiArtstation,
    "aspect-ratio": mdiAspectRatio,
    atlassian: mdiAtlassian,
    "atom-variant": mdiAtomVariant,
    attachment: mdiAttachment,
    "audio-video": mdiAudioVideo,
    "auto-fix": mdiAutoFix,
    autorenew: mdiAutorenew,
    "av-timer": mdiAvTimer,
    axe: mdiAxe,
    baby: mdiBaby,
    "baby-bottle": mdiBabyBottle,
    "baby-buggy": mdiBabyBuggy,
    "baby-carriage": mdiBabyCarriage,
    "baby-face": mdiBabyFace,
    "backup-restore": mdiBackupRestore,
    bacteria: mdiBacteria,
    "badge-account": mdiBadgeAccount,
    badminton: mdiBadminton,
    "bag-carry-on": mdiBagCarryOn,
    "bag-checked": mdiBagChecked,
    "bag-personal": mdiBagPersonal,
    baguette: mdiBaguette,
    balloon: mdiBalloon,
    bandage: mdiBandage,
    bank: mdiBank,
    "barcode-scan": mdiBarcodeScan,
    barley: mdiBarley,
    barn: mdiBarn,
    barrel: mdiBarrel,
    baseball: mdiBaseball,
    "baseball-bat": mdiBaseballBat,
    basket: mdiBasket,
    basketball: mdiBasketball,
    "basketball-hoop": mdiBasketballHoop,
    bat: mdiBat,
    "battery-outline": mdiBatteryOutline,
    battlenet: mdiBattlenet,
    beach: mdiBeach,
    beaker: mdiBeaker,
    bed: mdiBed,
    "bed-queen": mdiBedQueen,
    bee: mdiBee,
    beer: mdiBeer,
    bell: mdiBell,
    beta: mdiBeta,
    betamax: mdiBetamax,
    biathlon: mdiBiathlon,
    bicycle: mdiBicycle,
    bike: mdiBike,
    billboard: mdiBillboard,
    "billiards-rack": mdiBilliardsRack,
    binoculars: mdiBinoculars,
    biohazard: mdiBiohazard,
    blender: mdiBlender,
    blinds: mdiBlinds,
    "blood-bag": mdiBloodBag,
    blur: mdiBlur,
    "bolnisi-cross": mdiBolnisiCross,
    bolt: mdiBolt,
    bomb: mdiBomb,
    bone: mdiBone,
    book: mdiBook,
    "book-open-blank-variant": mdiBookOpenBlankVariant,
    "book-open-page-variant": mdiBookOpenPageVariant,
    bookmark: mdiBookmark,
    bookshelf: mdiBookshelf,
    "boom-gate": mdiBoomGate,
    boombox: mdiBoombox,
    boomerang: mdiBoomerang,
    "bottle-soda-classic": mdiBottleSodaClassic,
    "bottle-tonic": mdiBottleTonic,
    "bottle-wine": mdiBottleWine,
    "bow-tie": mdiBowTie,
    "bowl-mix": mdiBowlMix,
    bowling: mdiBowling,
    "box-cutter": mdiBoxCutter,
    "boxing-glove": mdiBoxingGlove,
    braille: mdiBraille,
    brain: mdiBrain,
    "bread-slice": mdiBreadSlice,
    bridge: mdiBridge,
    briefcase: mdiBriefcase,
    "briefcase-variant": mdiBriefcaseVariant,
    "brightness-7": mdiBrightness7,
    broom: mdiBroom,
    brush: mdiBrush,
    bucket: mdiBucket,
    buffer: mdiBuffer,
    buffet: mdiBuffet,
    bug: mdiBug,
    bugle: mdiBugle,
    bulldozer: mdiBulldozer,
    bullet: mdiBullet,
    "bulletin-board": mdiBulletinBoard,
    bullhorn: mdiBullhorn,
    "bullseye-arrow": mdiBullseyeArrow,
    "bunk-bed": mdiBunkBed,
    bus: mdiBus,
    "cable-data": mdiCableData,
    cactus: mdiCactus,
    "cake-variant": mdiCakeVariant,
    calculator: mdiCalculator,
    camcorder: mdiCamcorder,
    camera: mdiCamera,
    "camera-control": mdiCameraControl,
    "camera-gopro": mdiCameraGopro,
    "camera-iris": mdiCameraIris,
    campfire: mdiCampfire,
    candle: mdiCandle,
    candycane: mdiCandycane,
    cannabis: mdiCannabis,
    car: mdiCar,
    "car-back": mdiCarBack,
    "car-battery": mdiCarBattery,
    "car-child-seat": mdiCarChildSeat,
    "car-clutch": mdiCarClutch,
    "car-connected": mdiCarConnected,
    "car-convertible": mdiCarConvertible,
    "car-defrost-rear": mdiCarDefrostRear,
    "car-door": mdiCarDoor,
    "car-estate": mdiCarEstate,
    "car-hatchback": mdiCarHatchback,
    "car-lifted-pickup": mdiCarLiftedPickup,
    "car-limousine": mdiCarLimousine,
    "car-multiple": mdiCarMultiple,
    "car-parking-lights": mdiCarParkingLights,
    "car-pickup": mdiCarPickup,
    "car-seat": mdiCarSeat,
    "car-shift-pattern": mdiCarShiftPattern,
    "car-traction-control": mdiCarTractionControl,
    caravan: mdiCaravan,
    cards: mdiCards,
    "cards-club": mdiCardsClub,
    "cards-diamond": mdiCardsDiamond,
    "cards-heart": mdiCardsHeart,
    "cards-spade": mdiCardsSpade,
    carrot: mdiCarrot,
    cart: mdiCart,
    cash: mdiCash,
    "cash-register": mdiCashRegister,
    cassette: mdiCassette,
    "cast-audio": mdiCastAudio,
    "cast-connected": mdiCastConnected,
    castle: mdiCastle,
    cat: mdiCat,
    cctv: mdiCctv,
    "ceiling-light": mdiCeilingLight,
    "cellphone-basic": mdiCellphoneBasic,
    "cellphone-iphone": mdiCellphoneIphone,
    "cellphone-link": mdiCellphoneLink,
    "celtic-cross": mdiCelticCross,
    certificate: mdiCertificate,
    "chair-rolling": mdiChairRolling,
    "chair-school": mdiChairSchool,
    charity: mdiCharity,
    "check-bold": mdiCheckBold,
    checkerboard: mdiCheckerboard,
    cheese: mdiCheese,
    "chef-hat": mdiChefHat,
    "chemical-weapon": mdiChemicalWeapon,
    "chess-bishop": mdiChessBishop,
    "chess-king": mdiChessKing,
    "chess-knight": mdiChessKnight,
    "chess-pawn": mdiChessPawn,
    "chess-queen": mdiChessQueen,
    "chess-rook": mdiChessRook,
    "chili-mild": mdiChiliMild,
    chip: mdiChip,
    christianity: mdiChristianity,
    church: mdiChurch,
    cigar: mdiCigar,
    circle: mdiCircle,
    "circle-double": mdiCircleDouble,
    "circular-saw": mdiCircularSaw,
    city: mdiCity,
    "city-variant": mdiCityVariant,
    clipboard: mdiClipboard,
    clippy: mdiClippy,
    clock: mdiClock,
    "closed-caption": mdiClosedCaption,
    cloud: mdiCloud,
    clover: mdiClover,
    "coach-lamp": mdiCoachLamp,
    "coat-rack": mdiCoatRack,
    "code-json": mdiCodeJson,
    coffee: mdiCoffee,
    "coffee-maker": mdiCoffeeMaker,
    coffin: mdiCoffin,
    cogs: mdiCogs,
    collage: mdiCollage,
    comma: mdiComma,
    comment: mdiComment,
    compare: mdiCompare,
    compass: mdiCompass,
    "concourse-ci": mdiConcourseCi,
    "console-line": mdiConsoleLine,
    "contactless-payment": mdiContactlessPayment,
    "content-cut": mdiContentCut,
    "content-paste": mdiContentPaste,
    "content-save": mdiContentSave,
    contrast: mdiContrast,
    "controller-classic": mdiControllerClassic,
    cookie: mdiCookie,
    "coolant-temperature": mdiCoolantTemperature,
    corn: mdiCorn,
    counter: mdiCounter,
    cow: mdiCow,
    crane: mdiCrane,
    creation: mdiCreation,
    "credit-card": mdiCreditCard,
    cricket: mdiCricket,
    crosshairs: mdiCrosshairs,
    "crosshairs-gps": mdiCrosshairsGps,
    crown: mdiCrown,
    cryengine: mdiCryengine,
    "crystal-ball": mdiCrystalBall,
    cube: mdiCube,
    "cube-unfolded": mdiCubeUnfolded,
    cup: mdiCup,
    cupboard: mdiCupboard,
    cupcake: mdiCupcake,
    curling: mdiCurling,
    "current-ac": mdiCurrentAc,
    "current-dc": mdiCurrentDc,
    "cursor-default-click": mdiCursorDefaultClick,
    "cursor-default-gesture": mdiCursorDefaultGesture,
    "cursor-move": mdiCursorMove,
    "cursor-pointer": mdiCursorPointer,
    "data-matrix": mdiDataMatrix,
    database: mdiDatabase,
    "death-star": mdiDeathStar,
    "death-star-variant": mdiDeathStarVariant,
    "deathly-hallows": mdiDeathlyHallows,
    decagram: mdiDecagram,
    delete: mdiDelete,
    delta: mdiDelta,
    desk: mdiDesk,
    "desk-lamp": mdiDeskLamp,
    deskphone: mdiDeskphone,
    "desktop-classic": mdiDesktopClassic,
    "desktop-mac": mdiDesktopMac,
    "desktop-tower": mdiDesktopTower,
    "desktop-tower-monitor": mdiDesktopTowerMonitor,
    "developer-board": mdiDeveloperBoard,
    devices: mdiDevices,
    diabetes: mdiDiabetes,
    dialpad: mdiDialpad,
    diamond: mdiDiamond,
    "diamond-stone": mdiDiamondStone,
    "dice-1": mdiDice1,
    "dice-2": mdiDice2,
    "dice-3": mdiDice3,
    "dice-4": mdiDice4,
    "dice-5": mdiDice5,
    "dice-6": mdiDice6,
    "dice-multiple": mdiDiceMultiple,
    "dip-switch": mdiDipSwitch,
    directions: mdiDirections,
    "directions-fork": mdiDirectionsFork,
    disc: mdiDisc,
    dishwasher: mdiDishwasher,
    "diving-flippers": mdiDivingFlippers,
    "diving-helmet": mdiDivingHelmet,
    "diving-scuba": mdiDivingScuba,
    "diving-scuba-tank": mdiDivingScubaTank,
    "diving-scuba-tank-multiple": mdiDivingScubaTankMultiple,
    "diving-snorkel": mdiDivingSnorkel,
    dlna: mdiDlna,
    dna: mdiDna,
    dns: mdiDns,
    doctor: mdiDoctor,
    dog: mdiDog,
    "dog-side": mdiDogSide,
    dolby: mdiDolby,
    dolly: mdiDolly,
    domain: mdiDomain,
    "dome-light": mdiDomeLight,
    "domino-mask": mdiDominoMask,
    donkey: mdiDonkey,
    door: mdiDoor,
    "door-closed": mdiDoorClosed,
    "door-open": mdiDoorOpen,
    "doorbell-video": mdiDoorbellVideo,
    "dots-horizontal": mdiDotsHorizontal,
    "dots-vertical": mdiDotsVertical,
    douban: mdiDouban,
    download: mdiDownload,
    draw: mdiDraw,
    drawing: mdiDrawing,
    dresser: mdiDresser,
    drone: mdiDrone,
    duck: mdiDuck,
    dumbbell: mdiDumbbell,
    "dump-truck": mdiDumpTruck,
    "ear-hearing": mdiEarHearing,
    earth: mdiEarth,
    egg: mdiEgg,
    "egg-easter": mdiEggEaster,
    "eight-track": mdiEightTrack,
    eject: mdiEject,
    "electric-switch": mdiElectricSwitch,
    "electric-switch-closed": mdiElectricSwitchClosed,
    elephant: mdiElephant,
    "elevator-passenger": mdiElevatorPassenger,
    ellipse: mdiEllipse,
    email: mdiEmail,
    emoticon: mdiEmoticon,
    engine: mdiEngine,
    equal: mdiEqual,
    equalizer: mdiEqualizer,
    eraser: mdiEraser,
    escalator: mdiEscalator,
    ethereum: mdiEthereum,
    ethernet: mdiEthernet,
    "ethernet-cable": mdiEthernetCable,
    "ev-plug-ccs1": mdiEvPlugCcs1,
    "ev-plug-ccs2": mdiEvPlugCcs2,
    "ev-plug-chademo": mdiEvPlugChademo,
    "ev-plug-tesla": mdiEvPlugTesla,
    "ev-plug-type1": mdiEvPlugType1,
    "ev-plug-type2": mdiEvPlugType2,
    "ev-station": mdiEvStation,
    excavator: mdiExcavator,
    "exit-run": mdiExitRun,
    "expansion-card-variant": mdiExpansionCardVariant,
    eye: mdiEye,
    eyedropper: mdiEyedropper,
    face: mdiFace,
    "face-agent": mdiFaceAgent,
    "face-profile": mdiFaceProfile,
    "face-profile-woman": mdiFaceProfileWoman,
    "face-woman": mdiFaceWoman,
    factory: mdiFactory,
    fan: mdiFan,
    fax: mdiFax,
    feather: mdiFeather,
    "feature-search": mdiFeatureSearch,
    fencing: mdiFencing,
    "ferris-wheel": mdiFerrisWheel,
    ferry: mdiFerry,
    file: mdiFile,
    film: mdiFilm,
    filmstrip: mdiFilmstrip,
    filter: mdiFilter,
    finance: mdiFinance,
    fingerprint: mdiFingerprint,
    fire: mdiFire,
    "fire-extinguisher": mdiFireExtinguisher,
    "fire-hydrant": mdiFireHydrant,
    "fire-truck": mdiFireTruck,
    fireplace: mdiFireplace,
    firework: mdiFirework,
    fish: mdiFish,
    fishbowl: mdiFishbowl,
    flag: mdiFlag,
    flare: mdiFlare,
    flash: mdiFlash,
    flashlight: mdiFlashlight,
    flask: mdiFlask,
    "flask-empty": mdiFlaskEmpty,
    "fleur-de-lis": mdiFleurDeLis,
    "floor-lamp": mdiFloorLamp,
    "floor-lamp-dual": mdiFloorLampDual,
    "floor-lamp-variant": mdiFloorLampVariant,
    "floor-plan": mdiFloorPlan,
    floppy: mdiFloppy,
    "floppy-variant": mdiFloppyVariant,
    flower: mdiFlower,
    "flower-outline": mdiFlowerOutline,
    "flower-poppy": mdiFlowerPoppy,
    "flower-tulip": mdiFlowerTulip,
    "flower-tulip-outline": mdiFlowerTulipOutline,
    folder: mdiFolder,
    food: mdiFood,
    "food-apple": mdiFoodApple,
    "food-croissant": mdiFoodCroissant,
    "food-drumstick": mdiFoodDrumstick,
    "food-fork-drink": mdiFoodForkDrink,
    "food-steak": mdiFoodSteak,
    "food-variant": mdiFoodVariant,
    "foot-print": mdiFootPrint,
    football: mdiFootball,
    "football-helmet": mdiFootballHelmet,
    forklift: mdiForklift,
    forum: mdiForum,
    fountain: mdiFountain,
    "fountain-pen": mdiFountainPen,
    "fountain-pen-tip": mdiFountainPenTip,
    fridge: mdiFridge,
    "fruit-cherries": mdiFruitCherries,
    "fruit-citrus": mdiFruitCitrus,
    "fruit-grapes": mdiFruitGrapes,
    "fruit-pineapple": mdiFruitPineapple,
    "fruit-watermelon": mdiFruitWatermelon,
    fuel: mdiFuel,
    fuse: mdiFuse,
    "fuse-blade": mdiFuseBlade,
    gamepad: mdiGamepad,
    "gamepad-circle": mdiGamepadCircle,
    "gamepad-square": mdiGamepadSquare,
    "gamepad-variant": mdiGamepadVariant,
    "gantry-crane": mdiGantryCrane,
    garage: mdiGarage,
    "garage-open": mdiGarageOpen,
    "garage-open-variant": mdiGarageOpenVariant,
    "garage-variant": mdiGarageVariant,
    "gas-cylinder": mdiGasCylinder,
    "gas-station": mdiGasStation,
    gate: mdiGate,
    "gate-and": mdiGateAnd,
    "gate-nand": mdiGateNand,
    "gate-nor": mdiGateNor,
    "gate-not": mdiGateNot,
    "gate-open": mdiGateOpen,
    "gate-or": mdiGateOr,
    "gate-xnor": mdiGateXnor,
    "gate-xor": mdiGateXor,
    gauge: mdiGauge,
    gavel: mdiGavel,
    gesture: mdiGesture,
    ghost: mdiGhost,
    gift: mdiGift,
    "glass-cocktail": mdiGlassCocktail,
    "glass-flute": mdiGlassFlute,
    "glass-mug": mdiGlassMug,
    "glass-mug-variant": mdiGlassMugVariant,
    "glass-pint-outline": mdiGlassPintOutline,
    "glass-stange": mdiGlassStange,
    "glass-tulip": mdiGlassTulip,
    "glass-wine": mdiGlassWine,
    glasses: mdiGlasses,
    "globe-light": mdiGlobeLight,
    "globe-model": mdiGlobeModel,
    "go-kart": mdiGoKart,
    "go-kart-track": mdiGoKartTrack,
    gold: mdiGold,
    golf: mdiGolf,
    "golf-cart": mdiGolfCart,
    "golf-tee": mdiGolfTee,
    gondola: mdiGondola,
    "google-assistant": mdiGoogleAssistant,
    "google-cardboard": mdiGoogleCardboard,
    "google-downasaur": mdiGoogleDownasaur,
    "google-home": mdiGoogleHome,
    gradient: mdiGradient,
    grain: mdiGrain,
    grass: mdiGrass,
    "grave-stone": mdiGraveStone,
    "grease-pencil": mdiGreasePencil,
    grid: mdiGrid,
    "grid-large": mdiGridLarge,
    grill: mdiGrill,
    "guitar-acoustic": mdiGuitarAcoustic,
    "guitar-electric": mdiGuitarElectric,
    "guitar-pick": mdiGuitarPick,
    hail: mdiHail,
    "hair-dryer": mdiHairDryer,
    halloween: mdiHalloween,
    hamburger: mdiHamburger,
    hammer: mdiHammer,
    "hammer-screwdriver": mdiHammerScrewdriver,
    "hammer-wrench": mdiHammerWrench,
    hand: mdiHand,
    "hand-heart": mdiHandHeart,
    "hand-left": mdiHandLeft,
    "hand-okay": mdiHandOkay,
    "hand-peace": mdiHandPeace,
    "hand-right": mdiHandRight,
    "hand-saw": mdiHandSaw,
    "hand-water": mdiHandWater,
    handball: mdiHandball,
    handcuffs: mdiHandcuffs,
    handshake: mdiHandshake,
    hanger: mdiHanger,
    "hard-hat": mdiHardHat,
    harddisk: mdiHarddisk,
    "hat-fedora": mdiHatFedora,
    "hazard-lights": mdiHazardLights,
    head: mdiHead,
    headphones: mdiHeadphones,
    headset: mdiHeadset,
    "headset-dock": mdiHeadsetDock,
    heart: mdiHeart,
    helicopter: mdiHelicopter,
    help: mdiHelp,
    hexadecimal: mdiHexadecimal,
    hexagon: mdiHexagon,
    "hexagon-multiple": mdiHexagonMultiple,
    hexagram: mdiHexagram,
    highway: mdiHighway,
    hiking: mdiHiking,
    "hockey-puck": mdiHockeyPuck,
    "hockey-sticks": mdiHockeySticks,
    hololens: mdiHololens,
    home: mdiHome,
    "home-group": mdiHomeGroup,
    "home-modern": mdiHomeModern,
    "home-roof": mdiHomeRoof,
    hook: mdiHook,
    hops: mdiHops,
    horseshoe: mdiHorseshoe,
    "hospital-building": mdiHospitalBuilding,
    "hot-tub": mdiHotTub,
    "hours-24": mdiHours24,
    human: mdiHuman,
    "human-baby-changing-table": mdiHumanBabyChangingTable,
    "human-child": mdiHumanChild,
    "human-female": mdiHumanFemale,
    "human-female-boy": mdiHumanFemaleBoy,
    "human-female-girl": mdiHumanFemaleGirl,
    "human-greeting": mdiHumanGreeting,
    "human-handsdown": mdiHumanHandsdown,
    "human-handsup": mdiHumanHandsup,
    "human-male": mdiHumanMale,
    "human-male-boy": mdiHumanMaleBoy,
    "human-male-child": mdiHumanMaleChild,
    "human-male-female": mdiHumanMaleFemale,
    "human-male-girl": mdiHumanMaleGirl,
    "human-pregnant": mdiHumanPregnant,
    "human-wheelchair": mdiHumanWheelchair,
    hvac: mdiHvac,
    "hydro-power": mdiHydroPower,
    "ice-cream": mdiIceCream,
    "ice-pop": mdiIcePop,
    "id-card": mdiIdCard,
    image: mdiImage,
    incognito: mdiIncognito,
    infinity: mdiInfinity,
    information: mdiInformation,
    "instrument-triangle": mdiInstrumentTriangle,
    "invert-colors": mdiInvertColors,
    ipod: mdiIpod,
    island: mdiIsland,
    "iv-bag": mdiIvBag,
    jeepney: mdiJeepney,
    jellyfish: mdiJellyfish,
    "jump-rope": mdiJumpRope,
    kabaddi: mdiKabaddi,
    karate: mdiKarate,
    keg: mdiKeg,
    kettle: mdiKettle,
    kettlebell: mdiKettlebell,
    key: mdiKey,
    keyboard: mdiKeyboard,
    knife: mdiKnife,
    "knife-military": mdiKnifeMilitary,
    label: mdiLabel,
    ladybug: mdiLadybug,
    lamp: mdiLamp,
    lan: mdiLan,
    laptop: mdiLaptop,
    "laser-pointer": mdiLaserPointer,
    lasso: mdiLasso,
    latitude: mdiLatitude,
    "lava-lamp": mdiLavaLamp,
    "lead-pencil": mdiLeadPencil,
    leaf: mdiLeaf,
    "leaf-maple": mdiLeafMaple,
    leak: mdiLeak,
    "led-off": mdiLedOff,
    "led-on": mdiLedOn,
    "led-outline": mdiLedOutline,
    "led-strip": mdiLedStrip,
    "led-strip-variant": mdiLedStripVariant,
    leek: mdiLeek,
    library: mdiLibrary,
    "library-shelves": mdiLibraryShelves,
    license: mdiLicense,
    lifebuoy: mdiLifebuoy,
    "light-switch": mdiLightSwitch,
    lightbulb: mdiLightbulb,
    "lightbulb-cfl": mdiLightbulbCfl,
    "lightbulb-cfl-spiral": mdiLightbulbCflSpiral,
    "lightbulb-group": mdiLightbulbGroup,
    "lightbulb-group-outline": mdiLightbulbGroupOutline,
    "lightbulb-multiple": mdiLightbulbMultiple,
    "lightbulb-multiple-outline": mdiLightbulbMultipleOutline,
    "lightbulb-on-outline": mdiLightbulbOnOutline,
    "lightbulb-outline": mdiLightbulbOutline,
    lighthouse: mdiLighthouse,
    "lighthouse-on": mdiLighthouseOn,
    "lightning-bolt": mdiLightningBolt,
    lingerie: mdiLingerie,
    link: mdiLink,
    "link-variant": mdiLinkVariant,
    lipstick: mdiLipstick,
    lock: mdiLock,
    "lock-open": mdiLockOpen,
    "lock-open-variant": mdiLockOpenVariant,
    "lock-pattern": mdiLockPattern,
    "lock-smart": mdiLockSmart,
    locker: mdiLocker,
    "locker-multiple": mdiLockerMultiple,
    longitude: mdiLongitude,
    looks: mdiLooks,
    lungs: mdiLungs,
    magnet: mdiMagnet,
    "magnet-on": mdiMagnetOn,
    magnify: mdiMagnify,
    mail: mdiMail,
    mailbox: mdiMailbox,
    "mailbox-open": mdiMailboxOpen,
    map: mdiMap,
    "map-marker": mdiMapMarker,
    marker: mdiMarker,
    "math-compass": mdiMathCompass,
    medal: mdiMedal,
    "medical-bag": mdiMedicalBag,
    meditation: mdiMeditation,
    memory: mdiMemory,
    menu: mdiMenu,
    "menu-open": mdiMenuOpen,
    merge: mdiMerge,
    message: mdiMessage,
    meteor: mdiMeteor,
    "micro-sd": mdiMicroSd,
    microphone: mdiMicrophone,
    "microphone-variant": mdiMicrophoneVariant,
    microscope: mdiMicroscope,
    "microsoft-xbox": mdiMicrosoftXbox,
    microwave: mdiMicrowave,
    "midi-port": mdiMidiPort,
    minecraft: mdiMinecraft,
    "mini-sd": mdiMiniSd,
    minidisc: mdiMinidisc,
    mirror: mdiMirror,
    "mixed-martial-arts": mdiMixedMartialArts,
    molecule: mdiMolecule,
    "molecule-co": mdiMoleculeCo,
    "molecule-co2": mdiMoleculeCo2,
    monitor: mdiMonitor,
    moped: mdiMoped,
    "mother-heart": mdiMotherHeart,
    "mother-nurse": mdiMotherNurse,
    "motion-sensor": mdiMotionSensor,
    motorbike: mdiMotorbike,
    mouse: mdiMouse,
    "mouse-variant": mdiMouseVariant,
    movie: mdiMovie,
    "movie-roll": mdiMovieRoll,
    muffin: mdiMuffin,
    mushroom: mdiMushroom,
    music: mdiMusic,
    nail: mdiNail,
    nas: mdiNas,
    nature: mdiNature,
    "nature-people": mdiNaturePeople,
    necklace: mdiNecklace,
    needle: mdiNeedle,
    network: mdiNetwork,
    newspaper: mdiNewspaper,
    nfc: mdiNfc,
    ninja: mdiNinja,
    "nintendo-game-boy": mdiNintendoGameBoy,
    "nintendo-switch": mdiNintendoSwitch,
    noodles: mdiNoodles,
    notebook: mdiNotebook,
    nuke: mdiNuke,
    nut: mdiNut,
    nutrition: mdiNutrition,
    oar: mdiOar,
    octagon: mdiOctagon,
    octagram: mdiOctagram,
    offer: mdiOffer,
    "office-building": mdiOfficeBuilding,
    oil: mdiOil,
    "oil-lamp": mdiOilLamp,
    "oil-level": mdiOilLevel,
    "one-up": mdiOneUp,
    opacity: mdiOpacity,
    orbit: mdiOrbit,
    origin: mdiOrigin,
    ornament: mdiOrnament,
    "outdoor-lamp": mdiOutdoorLamp,
    owl: mdiOwl,
    "pac-man": mdiPacMan,
    package: mdiPackage,
    pail: mdiPail,
    palette: mdiPalette,
    "palette-swatch": mdiPaletteSwatch,
    "palm-tree": mdiPalmTree,
    panda: mdiPanda,
    panorama: mdiPanorama,
    "paper-roll": mdiPaperRoll,
    paperclip: mdiPaperclip,
    parachute: mdiParachute,
    "party-popper": mdiPartyPopper,
    passport: mdiPassport,
    "passport-biometric": mdiPassportBiometric,
    pasta: mdiPasta,
    "patio-heater": mdiPatioHeater,
    paw: mdiPaw,
    peace: mdiPeace,
    peanut: mdiPeanut,
    pen: mdiPen,
    pencil: mdiPencil,
    penguin: mdiPenguin,
    pentagon: mdiPentagon,
    "periodic-table": mdiPeriodicTable,
    pharmacy: mdiPharmacy,
    phone: mdiPhone,
    "phone-classic": mdiPhoneClassic,
    pi: mdiPi,
    "pi-hole": mdiPiHole,
    piano: mdiPiano,
    pickaxe: mdiPickaxe,
    pier: mdiPier,
    "pier-crane": mdiPierCrane,
    "pig-variant": mdiPigVariant,
    "piggy-bank": mdiPiggyBank,
    pill: mdiPill,
    pillar: mdiPillar,
    pin: mdiPin,
    "pine-tree": mdiPineTree,
    "pine-tree-fire": mdiPineTreeFire,
    pinwheel: mdiPinwheel,
    pipe: mdiPipe,
    "pipe-disconnected": mdiPipeDisconnected,
    "pipe-wrench": mdiPipeWrench,
    pirate: mdiPirate,
    pistol: mdiPistol,
    piston: mdiPiston,
    pizza: mdiPizza,
    plex: mdiPlex,
    plus: mdiPlus,
    podcast: mdiPodcast,
    podium: mdiPodium,
    "point-of-sale": mdiPointOfSale,
    "poker-chip": mdiPokerChip,
    "police-badge": mdiPoliceBadge,
    polo: mdiPolo,
    pool: mdiPool,
    popcorn: mdiPopcorn,
    "postage-stamp": mdiPostageStamp,
    "pot-mix": mdiPotMix,
    power: mdiPower,
    "power-plug": mdiPowerPlug,
    "power-socket": mdiPowerSocket,
    "power-socket-us": mdiPowerSocketUs,
    prescription: mdiPrescription,
    presentation: mdiPresentation,
    printer: mdiPrinter,
    "printer-3d": mdiPrinter3d,
    projector: mdiProjector,
    "projector-screen": mdiProjectorScreen,
    "propane-tank": mdiPropaneTank,
    pulse: mdiPulse,
    pump: mdiPump,
    pumpkin: mdiPumpkin,
    purse: mdiPurse,
    qi: mdiQi,
    qrcode: mdiQrcode,
    quadcopter: mdiQuadcopter,
    rabbit: mdiRabbit,
    "racing-helmet": mdiRacingHelmet,
    racquetball: mdiRacquetball,
    radar: mdiRadar,
    radiator: mdiRadiator,
    radio: mdiRadio,
    "radio-handheld": mdiRadioHandheld,
    "radio-tower": mdiRadioTower,
    radioactive: mdiRadioactive,
    "railroad-light": mdiRailroadLight,
    rake: mdiRake,
    "raspberry-pi": mdiRaspberryPi,
    receipt: mdiReceipt,
    rectangle: mdiRectangle,
    "rectangle-outline": mdiRectangleOutline,
    recycle: mdiRecycle,
    reminder: mdiReminder,
    remote: mdiRemote,
    "remote-tv": mdiRemoteTv,
    rice: mdiRice,
    ring: mdiRing,
    rivet: mdiRivet,
    "road-variant": mdiRoadVariant,
    robber: mdiRobber,
    robot: mdiRobot,
    "robot-industrial": mdiRobotIndustrial,
    "robot-mower": mdiRobotMower,
    "robot-vacuum": mdiRobotVacuum,
    "robot-vacuum-variant": mdiRobotVacuumVariant,
    rocket: mdiRocket,
    "rocket-launch": mdiRocketLaunch,
    rodent: mdiRodent,
    "roller-skate": mdiRollerSkate,
    rollerblade: mdiRollerblade,
    "room-service": mdiRoomService,
    router: mdiRouter,
    "router-network": mdiRouterNetwork,
    "router-wireless": mdiRouterWireless,
    routes: mdiRoutes,
    rowing: mdiRowing,
    rss: mdiRss,
    rug: mdiRug,
    rugby: mdiRugby,
    ruler: mdiRuler,
    "ruler-square": mdiRulerSquare,
    run: mdiRun,
    "run-fast": mdiRunFast,
    "rv-truck": mdiRvTruck,
    sack: mdiSack,
    safe: mdiSafe,
    "safe-square": mdiSafeSquare,
    "safe-square-outline": mdiSafeSquareOutline,
    "safety-goggles": mdiSafetyGoggles,
    "sail-boat": mdiSailBoat,
    "satellite-uplink": mdiSatelliteUplink,
    "satellite-variant": mdiSatelliteVariant,
    sausage: mdiSausage,
    "saw-blade": mdiSawBlade,
    saxophone: mdiSaxophone,
    scale: mdiScale,
    "scale-balance": mdiScaleBalance,
    "scale-bathroom": mdiScaleBathroom,
    scanner: mdiScanner,
    school: mdiSchool,
    scooter: mdiScooter,
    scoreboard: mdiScoreboard,
    "screw-flat-top": mdiScrewFlatTop,
    "screw-lag": mdiScrewLag,
    "screw-machine-flat-top": mdiScrewMachineFlatTop,
    "screw-machine-round-top": mdiScrewMachineRoundTop,
    "screw-round-top": mdiScrewRoundTop,
    screwdriver: mdiScrewdriver,
    sd: mdiSd,
    seal: mdiSeal,
    seat: mdiSeat,
    "seat-flat": mdiSeatFlat,
    "seat-flat-angled": mdiSeatFlatAngled,
    "seat-individual-suite": mdiSeatIndividualSuite,
    "seat-legroom-extra": mdiSeatLegroomExtra,
    "seat-legroom-normal": mdiSeatLegroomNormal,
    "seat-legroom-reduced": mdiSeatLegroomReduced,
    "seat-passenger": mdiSeatPassenger,
    "seat-recline-extra": mdiSeatReclineExtra,
    "seat-recline-normal": mdiSeatReclineNormal,
    seatbelt: mdiSeatbelt,
    security: mdiSecurity,
    seed: mdiSeed,
    send: mdiSend,
    "serial-port": mdiSerialPort,
    server: mdiServer,
    "server-network": mdiServerNetwork,
    "toggle-switch": mdiToggleSwitch,
    "toggle-switch-off": mdiToggleSwitchOff,
    thermometer: mdiThermometer,
    close: mdiClose,
    battery: mdiBattery,
    "battery-10": mdiBattery10,
    "battery-20": mdiBattery20,
    "battery-30": mdiBattery30,
    "battery-40": mdiBattery40,
    "battery-50": mdiBattery50,
    "battery-60": mdiBattery60,
    "battery-70": mdiBattery70,
    "battery-80": mdiBattery80,
    "battery-90": mdiBattery90,
    "water-outline": mdiWaterOutline,
    smog: mdiSmog,
    "dock-window": mdiDockWindow,
    snowflake: mdiSnowflake,
    update: mdiUpdate,
    "bell-outline": mdiBellOutline,
    "help-circle": mdiHelpCircle,
    "code-tags": mdiCodeTags,
    "drag-horizontal": mdiDragHorizontal,
    star: mdiStar,
    "message-draw": mdiMessageDraw,
    "chevron-down": mdiChevronDown,
    "chevron-up": mdiChevronUp,
    "plus-circle": mdiPlusCircle,
    "account-circle": mdiAccountCircle,
    "bug-check": mdiBugCheck,
    refresh: mdiRefresh,
    wrench: mdiWrench,
    "eye-off": mdiEyeOff,
    widgets: mdiWidgets,
    sprinkler: mdiSprinklerVariant,
    television: mdiTelevisionClassic,
};

export function icons(): string[] {
    return Object.keys(tags);
}

export function icon(name: string): string {
    return tags[name] || "";
}
